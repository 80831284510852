<template>
  <b-modal
    visible
    scrollable
    centered
    title="Просмотр протоколов"
    size="lg"
    no-close-on-backdrop
    @hidden="onClose"
    @ok="onClose"
  >
    <div v-if="protocolItems.length">
      <div class="bg-white w-100">
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="rowsInProtocolItems"
            :key="currentPage"
          >
            <b-table
              key="table"
              class="my-3"
              bordered
              size="sm"
              :fields="fields"
              :items="protocolItems"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #cell(nameDoctor)="row">
                {{ row.item.doctor.fullName }}
              </template>

              <template #cell(specialtyDoctor)="row">
                {{ row.item.specialization }}
              </template>

              <template #cell(dateClosedConsultation)="row">
                {{ formatDate(row.item.closeDate) }}
              </template>

              <template #cell(addProtocolBtn)="row">
                <b-button
                  v-b-tooltip.hover.right
                  variant="outline-success"
                  :type="$const.PRIMARY_BUTTON"
                  size="sm"
                  title="Протокол"
                  @click="getProtocol(row.item.consultationId)"
                >
                  <b-icon
                    icon="file-earmark-pdf"
                  />
                </b-button>
              </template>
            </b-table>
          </div>
        </transition>

        <b-pagination
          v-if="rowsInProtocolItems"
          v-model="currentPage"
          :total-rows="rowsInProtocolItems"
          :per-page="perPage"
          aria-controls="my-table"
        />
      </div>
    </div>
    <div v-else>
      <span><strong>Протоколы не найдены</strong></span>
    </div>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Закрыть
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { format } from '@evd3v/date-fns';

import { consultationService } from '@/services';

import { saveFile, utc0ToDateWithUserTimezone } from '@/helpers/utils';
import { showErrorCustomMessage } from '@/helpers/messages';

export default {
  name: 'ProtocolsModal',
  props: {
    personId: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      protocolItems: [],
      protocolFiles: [],
      perPage: 7,
      currentPage: 1,
      fields: [
        {
          key: 'nameDoctor',
          label: 'ФИО врача',
          thClass: ['text-center', 'align-middle'],
          tdClass: ['text-center', 'align-middle'],
        },
        {
          key: 'specialtyDoctor',
          label: 'Специальность врача',
          class: 'text-center',
          thClass: ['text-center', 'align-middle'],
          tdClass: ['text-center', 'align-middle'],
        },
        {
          key: 'dateClosedConsultation',
          label: 'Дата закрытия консультации',
          class: 'text-center',
          tdClass: ['text-center', 'align-middle'],
        },
        {
          key: 'addProtocolBtn',
          label: 'Файл',
          thClass: ['text-center', 'align-middle'],
          tdClass: ['text-center', 'align-middle'],
        },
      ],
    };
  },
  computed: {
    rowsInProtocolItems() {
      return this.protocolItems.length;
    },
  },
  async created() {
    try {
      const result = await consultationService.searchProtocols({ patientId: this.personId });
      this.protocolItems = result;
    } catch (err) {
      showErrorCustomMessage('Не удалось получить список протоколов');
      console.log(err);
    }
  },
  methods: {
    onClose() {
      this.$emit('input', false);
    },
    async getProtocol(id) {
      const protocol = await consultationService.getProtocol(id);
      const blob = new Blob([protocol], { type: 'application/pdf' });
      await saveFile(blob, { name: 'this.originName', open: true });
    },
    formatDate(date) {
      const newDate = utc0ToDateWithUserTimezone(date);
      return format(new Date(newDate), 'dd.MM.yyyy');
    },
  },
};
</script>

<style lang="scss" scoped>
.list-enter-active, .list-leave-active {
  transition: all .7s;
}
.list-enter, .list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity .3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
